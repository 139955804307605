import {
  IMemberRolePickerProps,
  IRoleOption,
  MemberRolePicker,
} from "src/components/Organizations/Organization/MemberRolePicker"
import { HomeGroupRole } from "src/data/homeGroups/types/homeGroupTypes"
import { langKeys } from "src/i18n/langKeys"

interface IHomeGroupRoleOptions extends IRoleOption<HomeGroupRole> {}
const homeGroupRoleOptions: IHomeGroupRoleOptions[] = [
  {
    label: langKeys.organizations_homegroup_admin_role_title,
    description: langKeys.organizations_homegroup_admin_role_short_description,
    role: HomeGroupRole.ADMIN,
  },
  {
    label: langKeys.organizations_homegroup_member_role_title,
    description: langKeys.organizations_homegroup_member_role_short_description,
    role: HomeGroupRole.MEMBER,
  },
  {
    label: langKeys.organizations_homegroup_responder_role_title,
    description:
      langKeys.organizations_homegroup_responder_role_short_description,
    role: HomeGroupRole.RESPONDER,
  },
  {
    label: langKeys.owner,
    description: ``,
    role: HomeGroupRole.OWNER,
    hidden: true,
  },
]

export function HomeGroupRolePicker(
  props: Omit<IMemberRolePickerProps<HomeGroupRole>, "options">
) {
  return <MemberRolePicker {...props} options={homeGroupRoleOptions} />
}
